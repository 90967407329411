import React from "react";
import {endpoint} from "../../lib/endpoint";
import AuthenticatedDataView from "../../components/AuthenticatedDataView";
import AreaView from "../../components/AreaView";

export default ({ params }) => {
  return <AuthenticatedDataView fetchUrl={`${endpoint}/provinces/${params.id}`}
    render={data => {
      console.log('data', data);
      return (<>
        <AreaView envelope_coordinates={data.province.envelope_coordinates} />
        <div>data={JSON.stringify(data)}</div>
      </>);
    }}
  />;
};

