import React from "react";
import AreaWithVenueMapView from "./AreaWithVenuesMapView";

type Props = {
  envelope_coordinates: number[][]
}

export default ({ envelope_coordinates }: Props) => {

  return <AreaWithVenueMapView initialBoundingBox={envelope_coordinates[0]} />;

}

