import React, {useState} from "react";
import {Box, Text} from "theme-ui";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvent,
  // useMap,
} from "react-leaflet";
import useHasMounted from "../lib/useHasMounted";
import ActionButton from "./ActionButton";
import LoadingIndicator from "./LoadingIndicator";
import {LatLngBoundsLiteral} from "leaflet";

type Props = {
  initialBoundingBox: number[][],
}

const LocationRefinementView = ({ initialBoundingBox }: Props) => {
  const hasMounted = useHasMounted();

  const bounds: LatLngBoundsLiteral = [
    [
      initialBoundingBox.reduce((min, cur) => Math.min(min, cur[1]), Number.MAX_VALUE),
      initialBoundingBox.reduce((min, cur) => Math.min(min, cur[0]), Number.MAX_VALUE),
    ],
    [
      initialBoundingBox.reduce((max, cur) => Math.max(max, cur[1]), Number.MAX_VALUE * -1),
      initialBoundingBox.reduce((max, cur) => Math.max(max, cur[0]), Number.MAX_VALUE * -1),
    ]
  ];

  console.log("initialBoundingBox=", initialBoundingBox);
  console.log("bounds=", bounds);

  const isBrowser = typeof window !== "undefined"
  if (hasMounted && isBrowser) {
    return (
      <>
        <MapContainer style={{ height: 300 }} bounds={bounds} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
        <Box sx={{ pt: 3 }}>
          <ActionButton text="What now?" onClick={() => console.log("clicked")} />
          <Box sx={{ pt: 3 }}>
            <Text>Something about listings and venues.</Text>
          </Box>
        </Box>
      </>
    );
  } else {
    return <LoadingIndicator />
  }
}

export default LocationRefinementView;

